export default function FormSkeleton({ inputFields }: any) {
  return (
    <div className="py-4 flex flex-col w-full lg:w-[63%]">
      <div className="flex flex-wrap justify-center gap-4 w-full mb-6">
        {inputFields.map((field: any) => {
          return (
            <div
              key={field.name}
              className="flex flex-col w-full sm:w-[48%] text-left"
            >
              <label
                htmlFor="htmlForm-delivery"
                id="htmlForm-delivery-l"
                className="bg-slate-200 w-1/3 h-[14px] mb-1 rounded-full"
              ></label>
              <div className="px-3 bg-slate-200  py-12 md:px-16 md:py-8 rounded-lg"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
