export class Ticket {
  fio: string | undefined = "";
  contact: string | undefined = "";
  problem: string | undefined = "";
  result: string = "13";

  constructor(initializer: any) {
    if (!initializer) return;
    if (initializer.fio) this.fio = initializer.fio;
    if (initializer.contact) this.contact = initializer.contact;
    if (initializer.problem) this.problem = initializer.problem;
    if (initializer.result) this.result = initializer.result;
  }
}
