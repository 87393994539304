import React, { SyntheticEvent, useEffect } from "react";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Certificate } from "../../Certificate";
import ButtonPrimary from "../../layout/ButtonPrimary";
import FormSkeleton from "../../layout/FormSkeleton";
import CompanyForm from "./CompanyForm";
import PublicForm from "./PublicForm";

interface MainFormProps {
  loading: boolean;
  onSubmit: (
    event: SyntheticEvent,
    data: Certificate,
    ref: React.RefObject<ReCAPTCHA>
  ) => void;
  validate: (certificate: Certificate) => void;
}

export default function MainForm({
  onSubmit,
  validate,
  loading,
}: MainFormProps) {
  const [data, setData] = useState<Certificate>(new Certificate({}));
  const [loadingIP, setLoadingIP] = useState<boolean>(true);
  const [clientIP, setClientIP] = useState<string>("");
  const [isDateInvoiceActive, setisDateInvoiceActive] = useState("");
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const inputFields = [
    {
      name: "date",
      text: `дата выпуска сертификата`,
      type: "date",
      public: true,
    },
    { name: "certification_number", text: "номер сертификата", public: true },
    {
      name: "date_invoice",
      text: "дата выпуска накладной",
      type: "date",
      public: true,
    },
    { name: "gov_number", text: "номер вагона (гос. номер)", public: true },
    { name: "delivery_number", text: "номер поставки", public: false },
    { name: "invoice_number", text: "номер накладной", public: false },
    { name: "contractor", text: "контрагент (ИНН)", public: false },
  ];

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    if (name === "date_invoice") {
      setisDateInvoiceActive("date_invoice");
      setData({ ...data, certification_number: "" });
    }
    if (name === "date_invoice" && value === "") {
      setisDateInvoiceActive("");
      setData({ ...data, certification_number: "" });
    }
    if (name === "date") {
      setisDateInvoiceActive("date");
      setData({ ...data, gov_number: "" });
    }
    if (name === "date" && value === "") {
      setisDateInvoiceActive("");
      setData({ ...data, gov_number: "" });
    }

    const change = {
      [name]: value.trim(),
    };

    let certificate: Certificate;
    setData((data) => {
      certificate = new Certificate({ ...data, ...change });
      return certificate;
    });
  };

  const getClientIP = () => {
    fetch("https://ipwho.is/")
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setClientIP(response.ip);
        setLoadingIP(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getClientIP();
  }, []);

  if (!loadingIP) {
    return (
      <form
        className="py-4 flex flex-col w-full lg:w-[63%]"
        onSubmit={(event) => onSubmit(event, data, recaptchaRef)}
        id="myhtmlForm"
      >
        <div
          className={`flex flex-wrap ${
            clientIP !== "91.205.209.58" ? "justify-center" : "justify-between"
          } items-end  gap-4 w-full mb-6`}
        >
          {clientIP !== "91.205.209.58" && (
            <PublicForm
              isDateInvoiceActive={isDateInvoiceActive}
              handleChange={handleChange}
              data={data}
            />
          )}
          {clientIP === "91.205.209.58" && (
            <CompanyForm
              isDateInvoiceActive={isDateInvoiceActive}
              handleChange={handleChange}
              data={data}
            />
          )}
        </div>
        <div className="m-auto mb-8">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdGoQohAAAAAEF-V_QJK7OJjabOmwDrEXRPNdGP"
            onChange={() => console.log()}
          />
        </div>
        {!loading && (
          <ButtonPrimary
            w="w-full sm:w-fit m-auto px-20 lg:px-32 pb-1 pt-1"
            text="найти"
            p="pb-1"
            size="xl"
            onClick={() => validate(data)}
          />
        )}
      </form>
    );
  } else {
    return <FormSkeleton inputFields={inputFields} />;
  }
}
