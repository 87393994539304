export class Certificate {
  delivery_number: string | undefined;
  invoice_number: string | undefined;
  date: string = "";
  date_invoice: string = "";
  contractor: string | undefined;
  gov_number: string | undefined = "";
  certification_number: string | undefined = "";

  constructor(initializer: any) {
    if (!initializer) return;
    if (initializer.delivery_number)
      this.delivery_number = initializer.delivery_number;
    if (initializer.invoice_number)
      this.invoice_number = initializer.invoice_number;
    if (initializer.date) this.date = initializer.date;
    if (initializer.date_invoice) this.date_invoice = initializer.date_invoice;
    if (initializer.contractor) this.contractor = initializer.contractor;
    if (initializer.gov_number) this.gov_number = initializer.gov_number;
    if (initializer.certification_number)
      this.certification_number = initializer.certification_number;
  }
}
