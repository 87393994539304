import Banner from "./components/home/Banner";
import Main from "./components/home/Main";

export default function Home() {
  return (
    <>
      <Banner />
      <Main />
    </>
  );
}
