interface PrimaryButtonProps {
  text: string;
  type?: "submit" | undefined;
  p?: string;
  size?: string;
  w?: string;
  onClick?: () => void;
}

export default function ButtonPrimary({
  text,
  p,
  w,
  size,
  type = undefined,
  onClick,
}: PrimaryButtonProps) {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`bg-secondary ${p && p} block duration-300 border-x border-y ${
        w && w
      } border-secondary text-white hover:bg-transparent hover:text-primary active:scale-95 ${
        size && "text-" + size
      }`}
    >
      {text}
    </button>
  );
}
