export default function Banner() {
  return (
    <div className="relative">
      <video
        className="h-[700px] w-screen object-cover"
        loop
        muted
        autoPlay
        playsInline
      >
        <source src="/assets/banner-video.mov" type="video/mp4" />
      </video>
      <h1 className=" leading-10 absolute top-[40%] text-[36px] left-[5%] right-[5%] text-white">
        Проверка подлинности <br />
        сертификатов качества <br />
        на продукцию
      </h1>
    </div>
  );
}
