import { Certificate } from "../../Certificate";

interface PublicFormProps {
  handleChange: (event: any) => void;
  isDateInvoiceActive: string;
  data: Certificate;
}

export default function PublicForm({
  handleChange,
  isDateInvoiceActive,
  data,
}: PublicFormProps) {
  return (
    <>
      <div className="flex flex-col w-full sm:w-2/3  text-left">
        <label htmlFor={"date"}>
          дата выпуска сертификата<span className="text-red-600">*</span>
        </label>
        <input
          type="date"
          required
          name="date"
          id="date"
          onChange={(event) => handleChange(event)}
          minLength={4}
          disabled={isDateInvoiceActive === "date_invoice" && true}
          className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
        />
      </div>
      <div className="flex flex-col w-full sm:w-2/3  text-left">
        <label htmlFor="certification_number">номер сертификата</label>
        <input
          type="text"
          name="certification_number"
          id="certification_number"
          onChange={(event) => handleChange(event)}
          minLength={4}
          disabled={isDateInvoiceActive === "date_invoice" && true}
          className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
          value={data.certification_number}
        />
      </div>
      <div className="flex flex-col w-full sm:w-2/3  text-left">
        <label htmlFor="date_invoice">
          дата выпуска накладной<span className="text-red-600">*</span>
        </label>
        <input
          type="date"
          required
          name="date_invoice"
          id="date_invoice"
          onChange={(event) => handleChange(event)}
          minLength={4}
          disabled={isDateInvoiceActive === "date" && true}
          className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
        />
      </div>
      <div className="flex flex-col w-full sm:w-2/3  text-left">
        <label htmlFor="gov_number">номер вагона (гос. номер)</label>
        <input
          type="text"
          name="gov_number"
          id="gov_number"
          onChange={(event) => handleChange(event)}
          minLength={4}
          disabled={isDateInvoiceActive === "date" && true}
          className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
          value={data.gov_number}
        />
      </div>
    </>
  );

  /* {
      name: "date",
      text: `дата выпуска сертификата`,
      type: "date",
      public: true,
    },
    { name: "certification_number", text: "номер сертификата", public: true },
    {
      name: "date_invoice",
      text: "дата выпуска накладной",
      type: "date",
      public: true,
    },
    { name: "gov_number", text: "номер вагона (гос. номер)", public: true },
    { name: "delivery_number", text: "номер поставки", public: false },
    { name: "invoice_number", text: "номер накладной", public: false },
    { name: "contractor", text: "контрагент (ИНН)", public: false }, */
}
