import { Certificate } from "../../Certificate";
import { getAllCertificatesLogs } from "../../api/api";

interface CompanyFormProps {
  handleChange: (event: any) => void;
  isDateInvoiceActive: string;
  data: Certificate;
}

export default function CompanyForm({
  handleChange,
  isDateInvoiceActive,
  data,
}: CompanyFormProps) {
  const handleDownload = async (certificate: any) => {
    const test = await getAllCertificatesLogs(1);
    console.log(test);
  };
  return (
    <>
      <div className="flex flex-col w-full sm:w-[48%] text-left">
        {/* <button onClick={handleDownload}>click</button> */}
        <label htmlFor={"date"}>
          дата выпуска сертификата<span className="text-red-600">*</span>
        </label>
        <input
          type="date"
          required
          name="date"
          id="date"
          onChange={(event) => handleChange(event)}
          minLength={4}
          disabled={isDateInvoiceActive === "date_invoice" && true}
          className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
        />
      </div>
      <div className="flex flex-col w-full sm:w-[48%] text-left">
        <label htmlFor="certification_number">номер сертификата</label>
        <input
          type="text"
          name="certification_number"
          id="certification_number"
          onChange={(event) => handleChange(event)}
          minLength={4}
          disabled={isDateInvoiceActive === "date_invoice" && true}
          className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
          value={data.certification_number}
        />
      </div>
      <div className="flex flex-col w-full sm:w-[48%] text-left">
        <label htmlFor="date_invoice">
          дата выпуска накладной<span className="text-red-600">*</span>
        </label>
        <input
          type="date"
          required
          name="date_invoice"
          id="date_invoice"
          onChange={(event) => handleChange(event)}
          minLength={4}
          disabled={isDateInvoiceActive === "date" && true}
          className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
        />
      </div>
      <div className="flex flex-col w-full sm:w-[48%] text-left">
        <label htmlFor="gov_number">номер вагона (гос. номер)</label>
        <input
          type="text"
          name="gov_number"
          id="gov_number"
          onChange={(event) => handleChange(event)}
          minLength={4}
          disabled={isDateInvoiceActive === "date" && true}
          className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
          value={data.gov_number}
        />
      </div>
      <div className="flex flex-col w-full sm:w-[48%] text-left">
        <label htmlFor="delivery_number">номер поставки</label>
        <input
          type="text"
          name="delivery_number"
          id="delivery_number"
          onChange={(event) => handleChange(event)}
          minLength={4}
          className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
        />
      </div>
      <div className="flex flex-col w-full sm:w-[48%] text-left">
        <label htmlFor="invoice_number">номер накладной</label>
        <input
          type="text"
          name="invoice_number"
          id="invoice_number"
          onChange={(event) => handleChange(event)}
          minLength={4}
          className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
        />
      </div>
      <div className="flex flex-col w-full sm:w-[48%] text-left">
        <label htmlFor="contractor">контрагент (ИНН)</label>
        <input
          type="text"
          name="contractor"
          id="contractor"
          onChange={(event) => handleChange(event)}
          minLength={4}
          className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
        />
      </div>
    </>
  );
}
