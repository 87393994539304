export default function Header() {
  return (
    <nav className="flex text-[30px] sm:text-[40px] justify-between px-[5%] py-6">
      <div className="flex">
        <h2 className="text-secondary self-end leading-10 tracking-tighter">
          Сертификаты качества
        </h2>
      </div>
      <div className="flex items-center flex-shrink-0 sm:items-end">
        <a href="http://magnezit.ru/ru/">
          <img
            src="/assets/logo-fixed.svg"
            className="sm:hidden h-[47px]"
            alt="logo"
          />
          <img
            className="hidden sm:block h-[47px]"
            src="/assets/logo.svg"
            alt="logo"
          />
        </a>
      </div>
    </nav>
  );
}
