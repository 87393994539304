import SupportModal from "./SupportModal";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="flex flex-col justify-center items-center gap-4 lg:flex-row  bg-[#dadada] px-[5%] py-8">
        <div className="justify-self-start  order-2 sm:-order-none lg:mr-auto lg:w-[240px]">
          © Группа Магнезит, {currentYear}
        </div>
        <div className="sm:justify-self-start order-1 sm:-order-none lg:m-auto">
          <div className="wrap-center-middle text-center ">
            <SupportModal name="Обратиться в поддержку" />
          </div>
        </div>
        <a
          href="http://magnezit.ru/ru/"
          className="justify-self-end order-3  sm:-order-none flex-shrink-0 lg:ml-auto"
        >
          <img
            className="sm:hidden h-[47px]"
            src="/assets/footer-logo-sm.svg"
            alt="footer logo sm"
          />
          <img
            className="hidden sm:block lg:w-[240px] h-[47px]"
            src="/assets/footer-logo.svg"
            alt="footer logo"
          />
        </a>
      </div>
    </footer>
  );
}
