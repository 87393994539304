import ButtonPrimary from "../../layout/ButtonPrimary";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { insertCertificatesLog } from "../../api/api";

export default function SearchResult({ certificates }: any) {
  const handleDownload = async (certificate: any) => {
    await insertCertificatesLog(certificate);
  };

  const handleZipDownload = async () => {
    const zip = new JSZip();
    const remotePDFs = certificates.map(async (cert: any) => {
      const response = await fetch(cert.link);
      const data = await response.blob();
      zip.file(`${cert["__name"].replace("()", "")}.pdf`, data);
      await insertCertificatesLog(cert);
      return data;
    });
    Promise.all(remotePDFs).then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "myname.zip");
      });
    });
  };

  return (
    <div className="w-full pt-14 text-left">
      <div className="w-full">
        <header>
          <ul className="flex flex-row font-bold pb-12">
            <li className="basis-3/6">Cертификат</li>
            <li className="basis-1/6 hidden md:block">Номер поставки</li>
            <li className="basis-1/6 hidden md:block">Накладная</li>
          </ul>
        </header>
        <ul className="search-result">
          {certificates.map((cert: any) => {
            return (
              <li
                key={cert["__id"]}
                className="flex flex-row items-center justify-between md:justify-start py-2 border-b border-[#d3d3d4] mb-4"
              >
                <span className="basis-5/6 md:basis-3/6 mr-2 md:mr-0">
                  {cert["__name"].replace("()", "")}
                </span>
                <span className="basis-1/6 hidden md:block">
                  {cert["delivery_number"]}
                </span>
                <span className="basis-1/6 hidden md:block">
                  {cert["invoice_number"]}
                </span>
                <a
                  href={cert.link}
                  onClick={() => handleDownload(cert)}
                  download
                  className={`bg-secondary cc ml-auto text-[18px] block duration-300 border border-secondary text-white px-1 pb-[0.16rem] hover:bg-transparent hover:text-primary active:scale-95`}
                >
                  cкачать
                </a>
              </li>
            );
          })}
        </ul>
        <ButtonPrimary
          onClick={handleZipDownload}
          w="m-auto px-4 py-1"
          text="скачать все"
        />
      </div>
    </div>
  );
}
