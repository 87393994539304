import { useState, SyntheticEvent } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Certificate } from "../../Certificate";
import MainForm from "./MainForm";
import SearchResult from "./SearchResult";

export default function Main() {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    totalInput: 0,
    text: "",
  });

  const handleSubmit = (
    event: SyntheticEvent,
    data: Certificate,
    recaptchaRef: React.RefObject<ReCAPTCHA>
  ) => {
    event.preventDefault();
    setLoading(true);
    setCertificates([]);
    if (errors.text) {
      setLoading(false);
      return;
    }

    const rec = recaptchaRef.current;
    const recaptchaValue = rec?.getValue();
    let formData = { ...data, r: recaptchaValue };

    fetch("/api/submit", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log(formData);
        rec?.reset();
        setLoading(false);
        if (res.status === 401) {
          setErrors((err) => ({
            ...err,
            text: "пройдите, пожалуйста, проверку",
          }));
        }
        if (res.status === 400) {
          setErrors((err) => ({
            ...err,
            text: "Сертификат не найден. Проверьте правильность введенных данных или повторите запрос позднее.",
          }));
        }
        if (res.status === 403) {
          setErrors((err) => ({
            ...err,
            text: "Минимум 2 поля должны быть заполнены",
          }));
        }

        return res.json();
      })
      .then((res) => {
        setCertificates(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validate = (certificate: Certificate) => {
    let errors = { totalInput: 0, text: "" };
    Object.values(certificate).forEach((val: any) => {
      if (val !== undefined && val !== 0) {
        errors.totalInput++;
      }
    });
    if (errors.totalInput < 2) {
      errors.text = "минимум 2 поля должны быть заполнены";
    }
    setErrors(errors);
  };

  return (
    <main className="flex flex-col items-center text-center px-[5%] pt-20 mb-10">
      <h2 className="text-xl text-[26px] font-bold pb-8">
        Здесь вы можете скачать сертификат качества продукции <br />
        или проверить его подлинность.
      </h2>
      <span className="text-lg pb-8 text-[20px]">
        Заполните форму для поиска сертификата. Поля, отмеченные
        <span className="text-red-600"> *</span>, обязательны для заполнения.{" "}
        <br />
        Минимум 2 поля должны быть заполнены.
      </span>
      <MainForm loading={loading} validate={validate} onSubmit={handleSubmit} />
      {errors.text && !loading && (
        <div className="text-red-600 pt-12">{errors.text}</div>
      )}
      {loading && (
        <img
          src="/assets/loading.svg"
          className="h-[75px] w-[75px]"
          alt="loading"
        />
      )}
      {!errors.text && !loading && certificates.length !== 0 && (
        <div className="w-full lg:w-[63%] pt-4">
          <div className=" text-green-600">сертификаты найдены</div>
          <SearchResult certificates={certificates} />
        </div>
      )}
    </main>
  );
}
