import { FC, InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  minLength?: number | undefined;
  cert?: boolean;
  // ref: MutableRefObject<HTMLInputElement | undefined> | null;
}

const Input: FC<InputProps> = ({ minLength, cert, ...rest }) => {
  // const onChange = (event: any) => {
  //   event.target.name === "date" ? "" : "";
  // };
  return (
    <input
      minLength={minLength}
      value={cert ? "" : undefined}
      // ref={ref}
      {...rest}
      className="px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
    />
  );
};
export default Input;
