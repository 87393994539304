// import axios from "axios";
import { Certificate } from "../Certificate";

// const api = axios.create({
//   //   baseURL: "http://localhost:5000/db",
//   baseURL: "http://192.168.222.239:5000/db",
// });

const apiUrl = "/db";

interface CertificatesLog {
  index?: number | undefined;
  date?: string | undefined;
  message?: string | undefined;
  error?: string | undefined;
}

const fetchApi = (url: string, options?: RequestInit) => {
  return fetch(`${apiUrl}${url}`, options).then((response) => response.json());
};

// export const insertCertificatesLog = (payload: Certificate) =>
//   api.post(`/certificateslog`, payload);
// export const getAllCertificatesLogs = (pageNum: number) =>
//   api.get(`/certificateslogs`, { params: { page: pageNum } });
// export const updateCertificatesLogById = (id: any, payload: Certificate) =>
//   api.put(`/certificateslog/${id}`, payload);
// export const deleteCertificatesLogById = (id: any) =>
//   api.delete(`/certificateslog/${id}`);
// export const getCertificatesLogById = (id: any) =>
//   api.get(`/certificateslog/${id}`);
export const insertCertificatesLog = (payload: Certificate) =>
  fetchApi(`/certificateslog`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

export const getAllCertificatesLogs = (pageNum: number) =>
  fetchApi(`/certificateslogs?page=${pageNum}`);

export const updateCertificatesLogById = (id: any, payload: Certificate) =>
  fetchApi(`/certificateslog/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

export const deleteCertificatesLogById = (id: any) =>
  fetchApi(`/certificateslog/${id}`, {
    method: "DELETE",
  });

export const getCertificatesLogById = (id: any) =>
  fetchApi(`/certificateslog/${id}`);

const apis = {
  insertCertificatesLog,
  getAllCertificatesLogs,
  updateCertificatesLogById,
  deleteCertificatesLogById,
  getCertificatesLogById,
};

export default apis;
