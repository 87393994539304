import React, { SyntheticEvent, useState } from "react";
import Modal from "react-modal";
import { Ticket } from "../Ticket";
import ButtonPrimary from "./ButtonPrimary";
import Input from "./input";
import ReCAPTCHA from "react-google-recaptcha";

Modal.setAppElement("#root");

export default function SupportModal({ name }: any) {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<Ticket>(new Ticket({}));
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState("");
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    const change = {
      [name]: value,
    };
    let ticket: Ticket;
    setData((data) => {
      ticket = new Ticket({ ...data, ...change });
      return ticket;
    });
  };

  const handleSubmit = (event: SyntheticEvent, data: Ticket) => {
    event.preventDefault();
    setLoading(true);
    const rec = recaptchaRef?.current;
    const recaptchaValue = rec?.getValue();
    const APIdata = {
      context: data,
      r: recaptchaValue,
    };
    fetch("/api/ticket", {
      method: "POST",
      body: JSON.stringify(APIdata),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setLoading(false);
        rec?.reset();
        if (res.status === 401) {
          setErrors("пройдите, пожалуйста, проверку");
          return;
        }
        setErrors("");
        setSuccess(true);
        setData(new Ticket({ fio: "", contact: "", problem: "" }));
        return res.json();
      })
      .catch((err) => {
        setSuccess(false);
        console.error(err);
      });
  };

  const handleClose = () => {
    setIsOpen(false);
    setSuccess(false);
  };

  return (
    <div>
      <button className="hover:font-bold" onClick={() => setIsOpen(true)}>
        {name}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleClose}
        contentLabel=""
        className=" w-[90%] top-[50%] translate-y-[-50%] sm:w-[40%] p-[5%] bg-white border-[#d3d3d4] border m-auto relative shadow-md"
      >
        <button
          className="top-5 right-5 absolute hover:font-bold"
          onClick={handleClose}
        >
          &#9587;
        </button>
        <form
          onSubmit={(event) => handleSubmit(event, data)}
          className="flex flex-col w-full"
          id="myhtmlForm"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col text-left mb-4 sm:w-full">
              <label htmlFor="name" id="htmlForm-name-l">
                ваше имя
              </label>
              <Input
                onChange={handleChange}
                type="text"
                name="fio"
                id="htmlForm-name"
                required
                value={data.fio}
              />
            </div>
            <div className="flex flex-col text-left mb-4 sm:w-full">
              <label htmlFor="htmlForm-num">email</label>
              <Input
                onChange={handleChange}
                name="contact"
                id="htmlForm-num"
                type="email"
                required
                value={data.contact}
              />
            </div>
            <div className="flex flex-col text-left mb-4 sm:w-full">
              <label htmlFor="htmlForm-num">сообщение</label>
              <textarea
                name="problem"
                onChange={handleChange}
                required
                value={data.problem}
                className="sm:h-[200px] px-3 focus:border-primary  py-4 md:px-6 md:py-5 border outline-none border-[#d3d3d4]"
              ></textarea>
            </div>
          </div>
          <div className="m-auto mb-4">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdGoQohAAAAAEF-V_QJK7OJjabOmwDrEXRPNdGP"
              onChange={() => console.log()}
            />
          </div>

          {loading ? (
            <img
              src="/assets/loading.svg"
              className="h-[75px] w-[75px] m-auto"
              alt="loading"
            />
          ) : (
            <ButtonPrimary text="отправить" p="p-2" />
          )}
          {errors && <span className="text-red-500 m-auto mt-4">{errors}</span>}
          {success && (
            <span className="text-[#a9a9aa] m-auto mt-4">
              благодарим за обращение
            </span>
          )}
        </form>
      </Modal>
    </div>
  );
}
